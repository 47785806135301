<template>
    <div class="user-wr" @mouseenter="isHoverd = true" @mouseleave="isHoverd = false" v-if="user">
        <div class="user">
            <div class="user__avatar">
                <img :src="user.avatar ? user.avatar : '/img/avatar.png'" alt="avatar">
            </div>
            <div class="user__full-name">
                <span>{{ user.first_name }} {{ user.last_name }}</span>
            </div>
            <div class="user__privilage" v-if="user.admin">
                <Icon name="star" :size="12" />
                <span class="privilage">Администратор</span>
            </div>
            <div class="user__settings-dots" @click="userSettingsActive = !userSettingsActive" v-if="isHoverd && activityStatus != 'deleted'">
                <Icon name="dots" :size="12" />
            </div>
        </div>
        <div class="user-settings" v-if="userSettingsActive && activityStatus != 'deleted'" v-click-outside="closeSettings">
            <router-link :to=toEditPage() class="settings__edit settings-field">
                <Icon name="pencil" :size="12" />
                <span>Редактировать</span>
            </router-link>
            <div class="settings__delete settings-field" @click="showDeleteContext = true" v-if="!user.creator && !owner">
                <Icon name="delete" :size="12" />
                <span>Удалить</span>
            </div>
            <div class="settings__isAdmin settings-field" v-if="!user.creator && !owner">
                <div @click="user.admin = !user.admin" class="settings__isAdmin-flex">
                    <Icon name="star" :size="12" />
                    <span>Администратор</span>
                </div>
                <ToggleSwitch ref="toggleSwitch" v-model="user.admin"/>
            </div>
        </div>
        <div class="user-recovery" v-if="isHoverd && activityStatus === 'deleted'" @click="recoverUser">
            <Icon name="recovery" :size="12" />
            <span class="user-recovery__label">Восстановить</span>
        </div>
        <DeleteContext v-if="showDeleteContext" @close="showDeleteContext = false" @delete="deleteUser">
            Удалить пользователя <span class="user__full-name" v-html="fullName" />
            <span class="delete-warning">
                Все файлы и сообщения этого пользователя удалятся через три месяца.
            </span>
        </DeleteContext>
    </div>
</template>
<script>
import ToggleSwitch from '@/components/ui/toggle-switch/ToggleSwitch.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import ApiClient from '@/api/api.client';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue'
import Notify from '@/services/helpers/notify';
import TeamsModule from '@/store/modules/teams/TeamsModule';
export default {
    props: ['user', 'activityStatus', 'owner'],
    components: { Icon, ToggleSwitch, DeleteContext },
    data() {
        return {
            userSettingsActive: false,
            isHoverd: false,
            showDeleteContext: false,
            isAdmin: false,
        }
    },
    methods: 
    {
        makeAdmin()
        {
            ApiClient.user.makeAdmin(this.user.id).then(() =>
            {
                Notify.success('Изменения сохранены')
            })
        },
        closeSettings()
        {
            this.userSettingsActive = false;
        },
        toEditPage()
        {
            return `/team/user/${this.user.id}/edit`
        },
        deleteUser()
        {
            if(this.owner)
            {
                Notify.success('Владельца удалить невозможно');
            }
            else
            {
                ApiClient.user.deleteUser(this.user.id).then(() => 
                {
                    if(this.user.admin)
                    {
                        Notify.success('Администратор удален')
                    }
                    else
                    {
                        Notify.success('Пользователь удален')
                    }
                    TeamsModule.deleteTeammate(this.user.id)
                    this.$emit('delete', this.user.id)
                })
            }
            this.closeSettings()
            this.showDeleteContext = false
        },
        recoverUser()
        {
            ApiClient.user.recoverUser(this.user.id).then(() => 
            {
                Notify.success('Пользователь восстановлен')
                this.updateUsers()
            })
        },
        updateUsers()
        {
            this.$emit('update')
        },
    },
    computed: 
    {
        fullName()
        {
            return `${this.user.first_name} ${this.user.last_name} ?`
        },
    },
    watch: 
    {
        'user.admin'()
        {
            this.makeAdmin();
        },
    },
}
</script>
<style lang="scss">
.user-wr
{
    .delete-context-wrp
    {
        .delete-context
        {
            width: 380px;
            padding: 26px 25px 26px 25px;
            margin-top: 0;
            margin-bottom: 30px;
            .delete-context__actions
            {
                justify-content: center;
            }
            .settings__isAdmin-flex
            {
                display: flex;
                align-items: center;
            }
            .user__full-name
            {
                display: block;
            }
            .delete-context__label
            {
                margin-top: 0;
                font-family: $font-family;
                line-height: 130%;
                text-align: center;
                color: rgba(255, 255, 255, 0.8);
                .delete-warning
                {
                    margin-top: 15px;
                    display: block;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
    .user-recovery
    {
        cursor: pointer;
        display: flex;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.5);
        position: absolute;
        z-index: 99;
        top: 11px;
        right: 16px;
        span
        {
            margin-left: 8px;
        }
    }
    position: relative;
    .user__settings-dots
    {
        cursor: pointer;
    }
}
.user-settings {
    right: 0;
    width: 214px;
    padding: 0 25px;
    .settings__edit 
    {
        display: block;
        color: rgba(255, 255, 255, 0.8);
    }
    .switch-togler
    {
        margin-left: 5px;
    }
    .settings__delete
    {
        display: flex;
        align-items: center;
        color: #B45039;
    }
    .settings-field
    {
        cursor: pointer;
        margin: 20px 0;
        span
        {
            margin-left: 15px;
        }
    }
    .settings__isAdmin
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.7);
    border-radius: 19px;
    background: #191919;
    z-index: 9999;
    border: 1px solid rgba($color: #fff, $alpha: 0.2);
    position: absolute;
}
</style>