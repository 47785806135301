<template>
    <div v-if="value" class="container-wr">
        <div v-for="(radioLabel, index) in radioLabels" :key="index">
            <label class="container" :class="innerClasses">
                <span class="radio-button__label" :class="{'active': checkedValue === radioLabel.value}">
                    {{ radioLabel.label }}
                </span>
                <input type="radio" v-model="checkedValue" :value="radioLabel.value" :class="{'active': checkedValue === radioLabel.value}"
                    :checked="checkedValue === radioLabel.value" />
                <span class="checkmark"></span>
            </label>
            <div v-show="radioLabel.additions" class="new-project__assessment-timing" :class="{'active-additions': checkedValue === radioLabel.value}">
                <div class="timing__option" v-for="item in radioLabel.additions" :key="item.value" :class="{'assessment-active': assesmentType === item.value}">
                    <label>
                        <span class="timing__option__label">
                            {{ item.title }}
                        </span>
                        <input type="radio" v-model="assesmentType" :value="item.value" :checked="assesmentType === item.value" name="assesment_timing" @change="setCheckParent($event)">
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Watch } from 'vue-property-decorator'
export default {
    props: {
        value: String, 
        label: String, 
        radioLabels: Array, 
        innerClasses: String,
        mode:String,
    },
    data() {
        return {
            checkedValue: '',
            assesmentType: '',
            pageLoaded: false,
        }
    },
    
    mounted()
    {
        this.setRemoteValue();
    },

    methods: 
    {
        setRemoteValue()
        {
            if(this.mode === 'edit')
            {
                if(this.value === 'monthly' || this.value === 'quarterly')
                {
                    this.assesmentType = this.value
                    this.checkedValue = 'periodically'
                }
                else
                {
                    this.checkedValue = this.value;
                }
            }
            else
            {
                this.checkedValue = this.value;
            }
        },
        setCheckParent(e)
        {
            this.checkedValue = 'periodically';
        },
    },
    watch: {
        value()
        {
            this.setRemoteValue();
        },
        assesmentType()
        {
            if(this.checkedValue === 'periodically')
            {
                this.$emit('change', this.assesmentType)
            }
        },
        checkedValue() {
            if(this.checkedValue != 'periodically')
            {
                this.assesmentType = ''
            }
            if (this.pageLoaded) {
                let returnValue;
                if(this.checkedValue === 'periodically' && this.assesmentType != '')
                    returnValue = this.assesmentType
                else
                {
                    returnValue = this.checkedValue
                }
                this.$emit('change', returnValue)
            }
            this.pageLoaded = true;
        },
    },
}
</script>
<style lang="scss" scoped>
.container-wr {
    display: flex;
}

.radio-button__label {
    display: block;
    padding-top: 31px;
}

.container .checkmark:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #191919;
    left: -3px;
    top: -3px;
    border: 3px solid #2c703f;
}

.container input:checked~.checkmark:after {
    display: block;
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.container input:checked~.checkmark {
    background-color: #2c703f;
}

.checkmark {
    position: absolute;
    top: 0;
    margin: 27px 0 40px 0;
    left: 0;
    height: 19px;
    width: 19px;
    background-color: #191919;
    border: 3px solid #444444;
    border-radius: 50%;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.container {
    margin-right: 50px;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-family: $font-family;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.8);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

</style>
