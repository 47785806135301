<template>
    <keep-alive>
        <div class="users-list" v-if="users || user">
            <User @delete="userDeleted($event)" v-for="user in users" :key="user.id" :user="user" :activityStatus="activityStatus" @update="update" />
            <User owner="true" :user="user" v-if="user"/>
        </div>
    </keep-alive>
</template>
<script>
import User from '@/modules/settings/views/teams/components/User.vue'
export default {
    props: ['users', 'activityStatus', 'user'],
    components: { User },
    methods: 
    {
        userDeleted(id)
        {
            this.$emit('delete', id)
        },
        update()
        {
            this.$emit('update')
        },
    },
}
</script>
<style lang="scss">
.users-list {
    .user__settings-dots
    {
        position: absolute;
        top: 11px;
        right: 10px;
    }
    .user-wr {
        position: relative;
        margin: 0 -10px;
    }

    .user {
        padding: 5px 10px;

        .user__privilage {
            display: flex;
            align-items: center;
            opacity: 0.5;
            margin-left: 12px;

            .privilage {
                font-size: 12px;
                margin-left: 5px;
            }
        }

        .user__avatar {
            display: flex;
            align-items: center;
            margin-right: 10px;

            img {
                height: 27px;
                width: 27px;
                border-radius: 50%;
            }
        }

        display: flex;
        align-items: center;
    }

    .user:hover {
        transition: .3s;
        background: rgba(27, 27, 27, 0.7);
        border-radius: 8px;
    }
}
</style>